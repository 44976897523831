import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadIcon } from '@heroicons/react/outline';
import { selectSubscription } from '../../store/slices/subscriptionSlice';
import { fetchSubscriptionStatus, cancelSubscription, updatePaymentMethod, changePlan } from '../../store/thunks/subscriptionThunks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentMethodModal from '../../components/subscription/PaymentMethodModal';
import ChangePlanModal from '../../components/subscription/ChangePlanModal';
import CancelSubscriptionModal from '../../components/subscription/CancelSubscriptionModal';
import ReactivationBanner from '../../components/subscription/ReactivationBanner';
import { reactivateSubscription } from '../../store/thunks/subscriptionThunks';
import { useSubscriptionToasts } from '../../hooks/useSubscriptionToasts';
import BillingHistory from '../../components/subscription/BillingHistory';



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const SubscriptionManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscription = useSelector(selectSubscription);
  console.log('SubscriptionManagement component:', {
    subscription,
    rawState: subscription,
    currentPlan: subscription?.currentPlan,
    status: subscription?.status,
    cancelAtPeriodEnd: subscription?.cancelAtPeriodEnd
  });
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const toasts = useSubscriptionToasts();


  useEffect(() => {
    dispatch(fetchSubscriptionStatus());
  }, [dispatch]);


  // Then add the handler function in the component:
  const handleReactivate = async () => {
    const loadingToast = toasts.loading.start('Reactivating subscription...');
    try {
      await dispatch(reactivateSubscription());
      toasts.loading.dismiss(loadingToast);
      toasts.success.subscriptionReactivated();
    } catch (error) {
      toasts.loading.dismiss(loadingToast);
      toasts.error.subscriptionReactivate(error);
    }
  };

  const handleUpdatePayment = async (paymentMethodId) => {
    const loadingToast = toasts.loading.start('Updating payment method...');
    try {
      await dispatch(updatePaymentMethod(paymentMethodId));
      setIsPaymentModalOpen(false);
      toasts.loading.dismiss(loadingToast);
      toasts.success.paymentUpdated();
    } catch (error) {
      toasts.loading.dismiss(loadingToast);
      toasts.error.paymentUpdate(error);
    }
  };

  const handleChangePlan = async (newPlanId) => {
    const loadingToast = toasts.loading.start('Changing plan...');
    try {
      await dispatch(changePlan(newPlanId));
      setIsPlanModalOpen(false);
      toasts.loading.dismiss(loadingToast);
      toasts.success.planChanged(newPlanId);
    } catch (error) {
      toasts.loading.dismiss(loadingToast);
      toasts.error.planChange(error);
    }
  };

  const handleCancelSubscription = async () => {
    const loadingToast = toasts.loading.start('Canceling subscription...');
    try {
      await dispatch(cancelSubscription());
      setIsCancelModalOpen(false);
      toasts.loading.dismiss(loadingToast);
      const endDate = new Date(subscription.currentPeriodEnd).toLocaleDateString();
      toasts.success.subscriptionCanceled(endDate);
    } catch (error) {
      toasts.loading.dismiss(loadingToast);
      toasts.error.subscriptionCancel(error);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getPlanAmount = (plan) => {
    const prices = {
      basic: { monthly: 9.99, yearly: 99.99 },
      pro: { monthly: 19.99, yearly: 199.99 },
      enterprise: { monthly: 49.99, yearly: 499.99 }
    };
    return prices[plan]?.[subscription.interval] || '9.99';
  };

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Current Plan */}
        <div className="bg-white shadow rounded-lg">
        <ReactivationBanner 
          subscription={subscription} 
          onReactivate={handleReactivate}
        />

          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Current Subscription
            </h3>
            <div className="mt-5">
              <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                <div className="sm:flex sm:items-start">
                  <div>
                    <h4 className="text-lg font-medium text-gray-900">
                      {subscription.currentPlan?.charAt(0).toUpperCase() + subscription.currentPlan?.slice(1)} Plan
                    </h4>
                    <p className="mt-1 text-sm text-gray-500">
                      ${getPlanAmount(subscription.currentPlan)}/{subscription.interval}
                    </p>
                  </div>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6">
                  <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${
                    subscription.status === 'active' 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {subscription.status?.charAt(0).toUpperCase() + subscription.status?.slice(1)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Billing Information */}
        <div className="mt-6 bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Billing Information
            </h3>
            <div className="mt-5">
              <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Next billing date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {formatDate(subscription.currentPeriodEnd)}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Payment method
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    •••• 4242 {/* TODO: Get from payment method */}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="mt-5 space-y-4">
          <button
            type="button"
            onClick={() => setIsPlanModalOpen(true)}  // This opens the plan change modal
            className="inline-flex items-center px-4 py-2 border border-blue-600 rounded-md shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Change Plan
          </button>
          <button
            type="button"
            onClick={() => setIsPaymentModalOpen(true)}  // This opens the payment modal
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Update Payment Method
          </button>
          <button
            type="button"
            onClick={() => setIsCancelModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel Subscription
          </button> 
        </div>

        {/* Billing History */}
        <BillingHistory />


        {/* Add the modals at the bottom of your component */}
        <Elements stripe={stripePromise}>
          <PaymentMethodModal
            isOpen={isPaymentModalOpen}
            onClose={() => setIsPaymentModalOpen(false)}
            onUpdate={handleUpdatePayment}
          />
        </Elements>

        <ChangePlanModal
          isOpen={isPlanModalOpen}
          onClose={() => setIsPlanModalOpen(false)}
          onChangePlan={handleChangePlan}
          currentPlan={subscription.currentPlan}
          interval={subscription.interval}
        />

        {/* Add the modal to your JSX */}
        <CancelSubscriptionModal
          isOpen={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onConfirm={handleCancelSubscription}
          subscription={subscription}
        />

      </div>
    </DashboardLayout>
  );
};

export default SubscriptionManagement;