import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Public Pages
import LandingPage from '../pages/LandingPage';
import SignIn from '../pages/Auth/SignIn';
import SignUp from '../pages/Auth/SignUp';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Subscribe from '../pages/Subscribe';
import NotFound from '../pages/NotFound';

// Protected Pages
import Dashboard from '../pages/Dashboard';
import Analytics from '../pages/Analytics';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';
import SubscriptionManagement from '../pages/SubscriptionManagement';
import TestPermissions from '../components/TestPermissions';
import UpgradePage from '../pages/upgrade'; // Fixed path
import ConnectBank from '../pages/ConnectBank'

// Components
import ProtectedRoute from '../components/routing/ProtectedRoute';
import SubscriptionGuard from '../components/guards/SubscriptionGuard';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<LandingPage />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/subscribe" element={<Subscribe />} />
      <Route path="/test-permissions" element={<TestPermissions />} />
      <Route path="/upgrade" element={<UpgradePage />} />
      <Route path="/connectBank" element={<ConnectBank />} />

      {/* Protected & Subscription Required Routes */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <SubscriptionGuard>
              <Dashboard />
            </SubscriptionGuard>
          </ProtectedRoute>
        }
      />
      <Route
        path="/analytics"
        element={
          <ProtectedRoute>
            <SubscriptionGuard>
              <Analytics />
            </SubscriptionGuard>
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/subscription"
        element={
          <ProtectedRoute>
            <SubscriptionManagement />
          </ProtectedRoute>
        }
      />

      {/* 404 Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;