// Define feature limits and capabilities for each subscription plan
const planFeatures = {
  basic: {
    maxProjects: 10,
    maxStorage: 5 * 1024 * 1024 * 1024, // 5GB in bytes
    features: {
      analytics: 'basic',
      support: 'email',
      collaborators: 1,
      customDomains: false,
      apiAccess: false,
      export: 'basic'
    },
    limits: {
      requestsPerDay: 1000,
      itemsPerProject: 100,
      modelOptions: ['basic']
    }
  },
  pro: {
    maxProjects: -1, // unlimited
    maxStorage: 50 * 1024 * 1024 * 1024, // 50GB
    features: {
      analytics: 'advanced',
      support: 'priority',
      collaborators: 10,
      customDomains: true,
      apiAccess: true,
      export: 'advanced'
    },
    limits: {
      requestsPerDay: 10000,
      itemsPerProject: 1000,
      modelOptions: ['basic', 'advanced']
    }
  },
  enterprise: {
    maxProjects: -1,
    maxStorage: -1, // unlimited
    features: {
      analytics: 'enterprise',
      support: 'dedicated',
      collaborators: -1,
      customDomains: true,
      apiAccess: true,
      export: 'enterprise'
    },
    limits: {
      requestsPerDay: -1,
      itemsPerProject: -1,
      modelOptions: ['basic', 'advanced', 'enterprise']
    }
  }
};

export default planFeatures;