import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import api from '../../services/api';
import BankAccountsList from './components/BankAccountsList';


const TELLER_ENVIRONMENT = process.env.REACT_APP_TELLER_ENV || 'sandbox';
const TELLER_APPLICATION_ID = process.env.REACT_APP_TELLER_APP_ID;

// Add this at the top of your component
console.log('Environment Variables:', {
  NODE_ENV: process.env.NODE_ENV,
  all_env: process.env,
  TELLER_ENV: process.env.REACT_APP_TELLER_ENV,
  TELLER_APP_ID: process.env.REACT_APP_TELLER_APP_ID,
  computed: {
    env: TELLER_ENVIRONMENT,
    appId: TELLER_APPLICATION_ID
  }
});

const ConnectBank = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isTellerLoaded, setIsTellerLoaded] = useState(false);

  useEffect(() => {
    // Add Teller Connect script dynamically
    const script = document.createElement('script');
    script.src = 'https://cdn.teller.io/connect/connect.js';
    script.async = true;
    script.onload = () => setIsTellerLoaded(true);
    script.onerror = () => setError('Failed to load Teller Connect. Please refresh the page.');
    document.body.appendChild(script);

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleConnectBank = async () => {
    setIsLoading(true);
    setError(null);
    console.log('Teller Config:', {
      env: process.env.REACT_APP_TELLER_ENV,
      appId: process.env.REACT_APP_TELLER_APP_ID
    });
    
    try {
      if (!window.TellerConnect) {
        throw new Error('Teller Connect not initialized');
      }

      const teller = window.TellerConnect.setup({
        applicationId: TELLER_APPLICATION_ID,
        environment: TELLER_ENVIRONMENT,
        onSuccess: async (enrollment) => {
          console.log('Raw enrollment data:', enrollment);
        
          try {
            // First save the bank connection
            const payload = {
              enrollment_id: enrollment.enrollment.id,
              access_token: enrollment.accessToken,
              institution: {
                name: enrollment.enrollment.institution.name,
                id: enrollment.enrollment.institution.id
              },
              accounts: []  // Start with empty accounts
            };
        
            // Save the bank connection
            const response = await api.post('/api/v1/banks/connections', payload);
            console.log('Bank connection saved:', response.data);
        
            // Now sync the accounts
            const connectionId = response.data.connection_id;
            await api.get(`/api/v1/banks/connections/${connectionId}/sync-accounts`);
        
            console.log('Bank connection and accounts synced successfully');
            navigate('/dashboard', { 
              state: { 
                success: true, 
                message: 'Bank connected successfully!' 
              }
            });
          } catch (err) {
            console.error('Failed to save bank connection:', err);
            setError(err.response?.data?.detail || 'Failed to save bank connection. Please try again.');
          }
          setIsLoading(false);
        },
        onExit: () => {
          console.log('User exited Teller Connect');
          setIsLoading(false);
        },
        onError: (error) => {
          console.error('Teller Connect error:', error);
          setError('Failed to connect to bank. Please try again.');
          setIsLoading(false);
        },
      });

      teller.open();
    } catch (err) {
      console.error('Connection error:', err);
      setError(err.message || 'Failed to initialize bank connection');
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
        <h1 className="text-2xl font-bold mb-2">Connect Your Bank</h1>
        <p className="text-gray-600 mb-6">
          Securely connect your bank account using Teller
        </p>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md mb-4">
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        )}
        
        <button 
          onClick={handleConnectBank} 
          disabled={isLoading || !isTellerLoaded}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 disabled:bg-blue-300"
        >
          {isLoading ? 'Connecting...' : 'Connect Bank Account'}
        </button>
        
        <p className="text-sm text-gray-500 text-center mt-4">
          Your banking credentials are encrypted and never stored on our servers
        </p>
      </div>

      <div className="mt-8">
        <BankAccountsList />
      </div>
    </DashboardLayout>
  );
};

export default ConnectBank;