// components/AccountSelector.jsx
const AccountSelector = ({ accounts, selectedAccount, onSelect, isLoading }) => {
  return (
    <select
      value={selectedAccount?.id || ''}
      onChange={(e) => {
        const account = accounts.find(acc => acc.id === e.target.value);
        onSelect(account);
      }}
      disabled={isLoading}
      className="block w-60 rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
    >
      <option value="">Select bank account</option>
      {accounts.map(account => (
        <option key={account.id} value={account.id}>
          {account.bank_name} - {account.account_name} (*{account.account_number_last4})
        </option>
      ))}
    </select>
  );
};

export default AccountSelector