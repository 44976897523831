// store/slices/analyticsSlice.js
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  summary: {
    total_sessions: 0,
    avg_session_time: 0,
    page_views: 0,
    features_used: []
  },
  loading: false,
  error: null,
  timeRange: '7d'
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setAnalyticsSummary: (state, action) => {
      state.summary = action.payload;
    },
    setTimeRange: (state, action) => {
      state.timeRange = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const {
  setAnalyticsSummary,
  setTimeRange,
  setLoading,
  setError
} = analyticsSlice.actions;

export const selectAnalyticsSummary = (state) => state.analytics.summary;
export const selectAnalyticsLoading = (state) => state.analytics.loading;
export const selectAnalyticsError = (state) => state.analytics.error;
export const selectTimeRange = (state) => state.analytics.timeRange;

export default analyticsSlice.reducer;