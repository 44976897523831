import React, { lazy, Suspense } from 'react';
import SEO from '../../components/common/SEO';
import Navbar from '../../components/Navbar';
import LandingHero from './components/LandingHero';

// Lazy load components
const LandingSocialProof = lazy(() => import('./components/LandingSocialProof'));
const LandingBenefits = lazy(() => import('./components/LandingBenefits'));
const LandingHowItWorks = lazy(() => import('./components/LandingHowItWorks'));
const LandingFAQ = lazy(() => import('./components/LandingFAQ'));
const Footer = lazy(() => import('../../components/Footer'));
const LandingPricing = lazy(() => import('./components/LandingPricing'));
const LandingIntegrations = lazy(() => import('./components/LandingIntegrations'));
const LandingSecurity  = lazy(() => import('./components/LandingSecurity'));
const LandingStats = lazy(() => import('./components/LandingStats'));


const LoadingFallback = () => (
  <div className="h-96 flex items-center justify-center">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <SEO 
        title="CGPT - AI-Powered Financial Assistant"
        description="Connect your bank accounts and get instant insights, analysis, and answers about your finances powered by multiple AI models including GPT-3.5, Claude, and Grok."
        canonical="https://cgpt.com"
      />
      
      <Navbar />
      
      <main>
        <LandingHero />

        <Suspense fallback={<LoadingFallback />}>
          <section id="social-proof" className="bg-white py-16">
            <div className="container mx-auto px-4 max-w-6xl">
              <LandingSocialProof />
            </div>
          </section>

          <section id="benefits" className="py-16">
            <div className="container mx-auto px-4 max-w-6xl">
              <LandingBenefits />
            </div>
          </section>

          <section id="pricing" className="bg-gray-50">
            <div className="container mx-auto px-4">
              <LandingPricing />
            </div>
          </section>

          <section id="how-it-works" className="bg-white py-16">
            <div className="container mx-auto px-4 max-w-6xl">
              <LandingHowItWorks />
            </div>
          </section>

          <section id="how-it-works" className="bg-white py-16">
            <div className="container mx-auto px-4 max-w-6xl">
              <LandingIntegrations />
            </div>
          </section>

          <section id="how-it-works" className="bg-white py-16">
            <div className="container mx-auto px-4 max-w-6xl">
              <LandingSecurity />
            </div>
          </section>

          <section id="how-it-works" className="bg-white py-16">
            <div className="container mx-auto px-4 max-w-6xl">
              <LandingStats />
            </div>
          </section>

          <section id="faq" className="py-16">
            <div className="container mx-auto px-4 max-w-6xl">
              <LandingFAQ />
            </div>
          </section>

          <Footer />
        </Suspense>
      </main>
    </div>
  );
};

export default LandingPage;