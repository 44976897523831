// Password strength enum
export const PasswordStrength = {
  WEAK: 'weak',
  MEDIUM: 'medium',
  STRONG: 'strong',
  VERY_STRONG: 'very_strong'
};

// Password validation helper functions
const hasLowerCase = (str) => /[a-z]/.test(str);
const hasUpperCase = (str) => /[A-Z]/.test(str);
const hasNumber = (str) => /\d/.test(str);
const hasSpecialChar = (str) => /[!@#$%^&*(),.?":{}|<>]/.test(str);
const hasMinLength = (str, min) => str.length >= min;

// Enhanced password validator
export const validatePassword = (value) => {
  if (!value) return { isValid: false, error: 'Password is required' };

  const requirements = [
    { test: hasMinLength(value, 8), message: 'at least 8 characters' },
    { test: hasLowerCase(value), message: 'one lowercase letter' },
    { test: hasUpperCase(value), message: 'one uppercase letter' },
    { test: hasNumber(value), message: 'one number' },
    { test: hasSpecialChar(value), message: 'one special character' }
  ];

  const failedRequirements = requirements
    .filter(req => !req.test)
    .map(req => req.message);

  const strengthScore = requirements.filter(req => req.test).length;

  let strength = PasswordStrength.WEAK;
  if (strengthScore >= 3) strength = PasswordStrength.MEDIUM;
  if (strengthScore >= 4) strength = PasswordStrength.STRONG;
  if (strengthScore === 5) strength = PasswordStrength.VERY_STRONG;

  return {
    isValid: failedRequirements.length === 0,
    error: failedRequirements.length > 0 
      ? `Password must contain ${failedRequirements.join(', ')}` 
      : '',
    strength,
    strengthScore
  };
};

// Enhanced email validator
export const validateEmail = (value) => {
  if (!value) return { isValid: false, error: 'Email is required' };
  
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const isValidFormat = emailRegex.test(value);
  
  // Additional checks
  const hasValidDomain = value.split('@')[1]?.includes('.');
  const isNotTemporary = !value.toLowerCase().includes('temporary') && 
                        !value.toLowerCase().includes('temp');

  return {
    isValid: isValidFormat && hasValidDomain && isNotTemporary,
    error: !isValidFormat ? 'Please enter a valid email address' :
           !hasValidDomain ? 'Please enter a valid domain' :
           !isNotTemporary ? 'Temporary email addresses are not allowed' : ''
  };
};

// Other enhanced validators...
export const validateName = (value) => {
  if (!value) return { isValid: false, error: 'Name is required' };
  
  const nameRegex = /^[a-zA-Z\s'-]{2,50}$/;
  const isValidFormat = nameRegex.test(value);
  const hasNoConsecutiveSpaces = !/\s{2,}/.test(value);
  
  return {
    isValid: isValidFormat && hasNoConsecutiveSpaces,
    error: !isValidFormat ? 'Name must be 2-50 characters and contain only letters, spaces, hyphens and apostrophes' :
           !hasNoConsecutiveSpaces ? 'Name cannot contain consecutive spaces' : ''
  };
};

export const validatePhone = (value) => {
  if (!value) return { isValid: true, error: '' }; // Optional field
  
  const phoneRegex = /^\+?[\d\s-()]{10,}$/;
  const hasValidLength = value.replace(/\D/g, '').length >= 10;
  
  return {
    isValid: phoneRegex.test(value) && hasValidLength,
    error: !hasValidLength ? 'Phone number must have at least 10 digits' :
           'Please enter a valid phone number'
  };
};

export const validateMessage = (value) => {
  if (!value) return { isValid: false, error: 'Message is required' };
  
  const hasMinLength = value.length >= 10;
  const hasMaxLength = value.length <= 1000;
  const hasNoURLs = !/https?:\/\//.test(value);
  
  return {
    isValid: hasMinLength && hasMaxLength && hasNoURLs,
    error: !hasMinLength ? 'Message must be at least 10 characters' :
           !hasMaxLength ? 'Message must be less than 1000 characters' :
           !hasNoURLs ? 'Message cannot contain URLs' : ''
  };
};