// src/pages/Dashboard/index.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBankAccounts } from '../../store/thunks/bankThunks';
import { sendChatMessage, fetchChatHistory } from '../../store/thunks/chatThunks';
import { selectBankAccounts, selectBankLoading } from '../../store/slices/bankSlice';
import { selectChatMessages, selectChatLoading, clearMessages, addMessage } from '../../store/slices/chatSlice';
import { selectChats } from '../../store/slices/chatHistorySlice';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import ChatHeader from './components/ChatHeader';
import ChatInput from './components/ChatInput';
import ChatMessages from './components/ChatMessages';
import ChatHistory from '../../components/chat/ChatHistory';
import { MODEL_REQUIREMENTS } from '../../components/guards/SubscriptionGuard';
import { toast } from 'react-hot-toast';

const AI_MODELS = [
  { id: 'gpt-3.5-turbo', name: 'GPT-3.5' },
  { id: 'grok-beta', name: 'Grok' },
  { id: 'claude-3-sonnet-20240229', name: 'Claude' }
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const bankAccounts = useSelector(selectBankAccounts);
  const isBankLoading = useSelector(selectBankLoading);
  const messages = useSelector(selectChatMessages);
  const isChatLoading = useSelector(selectChatLoading);
  const subscription = useSelector(state => state.subscription);
  const chatHistoryLoading = useSelector(state => state.chatHistory.loading);
  const chats = useSelector(selectChats);
  
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [input, setInput] = useState('');
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(null);

  // Filter available models based on subscription
  const availableModels = AI_MODELS.filter(model => {
    const requiredPlans = MODEL_REQUIREMENTS[model.id];
    return requiredPlans?.includes(subscription.currentPlan);
  });

  // Set initial model after filtering available models
  useEffect(() => {
    if (!selectedModel && availableModels.length > 0) {
      setSelectedModel(availableModels[0]);
    }
  }, [availableModels, selectedModel]);

  const handleModelSelect = (model) => {
    const requiredPlans = MODEL_REQUIREMENTS[model.id];
    if (!requiredPlans?.includes(subscription.currentPlan)) {
      toast.error(`Your current plan doesn't have access to ${model.name}. Please upgrade your plan.`);
      return;
    }
    setSelectedModel(model);
  };

  // const chats = useSelector(state => {
  //   console.log('Full Redux State:', state);
  //   return selectChats(state);
  // });

  // In Dashboard/index.js
  const handleHistoryClick = () => {
    console.log('History button clicked, current state:', isHistoryOpen);
    setIsHistoryOpen(prev => !prev);
    console.log('History state after toggle:', !isHistoryOpen);
  };

  useEffect(() => {
    console.log('Dashboard mounted');
    const loadData = async () => {
      try {
        console.log('Fetching chat history...');
        await dispatch(fetchChatHistory());
        console.log('Chat history fetched');
      } catch (error) {
        console.error('Error loading chat history:', error);
      }
    };
    loadData();
  }, [dispatch]);

  useEffect(() => {
    console.log('Chats updated:', chats);
  }, [chats]);

  useEffect(() => {
    dispatch(fetchBankAccounts()).catch((error) => {
      console.error('Failed to fetch bank accounts:', error);
    });

    return () => {
      dispatch(clearMessages());
    };
  }, [dispatch]);

  const handleSelectChat = (chat) => {
    setCurrentChatId(chat.id);
    dispatch(clearMessages());
    chat.messages?.forEach(msg => {
      dispatch(addMessage({
        role: msg.role,
        content: msg.content
      }));
    });
    setIsHistoryOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || !selectedAccount) return;

    dispatch(addMessage({ role: 'user', content: input }));
    const message = input;
    setInput('');

    try {
      await dispatch(sendChatMessage({
        message,
        accountId: selectedAccount.id,
        modelId: selectedModel.id,
        chatId: currentChatId
      }));
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  return (
    <DashboardLayout>
      <div className="h-screen flex flex-col bg-gray-50">
        <ChatHeader
          selectedAccount={selectedAccount}
          selectedModel={selectedModel}
          onAccountSelect={setSelectedAccount}
          onModelSelect={handleModelSelect}
          accounts={bankAccounts}
          models={availableModels}  // Pass filtered models
          currentPlan={subscription.currentPlan}  // Pass current plan
          isLoading={isBankLoading || chatHistoryLoading}
          onHistoryClick={handleHistoryClick}
        />
        
        <ChatHistory
          isOpen={isHistoryOpen}
          onClose={() => setIsHistoryOpen(false)}
          chats={chats}
          onSelectChat={handleSelectChat}
          isLoading={isBankLoading || chatHistoryLoading}
        />

        <div className="flex-1 overflow-hidden flex flex-col max-w-4xl mx-auto w-full">
          <ChatMessages messages={messages} isLoading={isChatLoading} />
          <ChatInput
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onSubmit={handleSubmit}
            isDisabled={!selectedAccount || isChatLoading}
            placeholder={selectedAccount ? "Ask about your finances..." : "Please select a bank account first"}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;