import api from '../../services/api';
import {
  setBankLoading,
  setBankError,
  setBankAccounts,
  addBankConnection,
  removeBankConnection
} from '../slices/bankSlice';
import { addNotification } from '../slices/uiSlice';

export const saveBankConnection = (enrollmentData) => async (dispatch) => {
  try {
    dispatch(setBankLoading(true));
    const response = await api.post('/api/v1/banks/connections', enrollmentData);
    dispatch(addBankConnection(response.data));
    dispatch(addNotification({
      type: 'success',
      message: 'Bank connected successfully!'
    }));
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.detail || 'Failed to save bank connection';
    dispatch(setBankError(errorMessage));
    dispatch(addNotification({
      type: 'error',
      message: errorMessage
    }));
    throw error;
  } finally {
    dispatch(setBankLoading(false));
  }
};

export const fetchBankAccounts = () => async (dispatch) => {
  try {
    dispatch(setBankLoading(true));
    const response = await api.get('/api/v1/banks/accounts');
    dispatch(setBankAccounts(response.data));
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.detail || 'Failed to fetch bank accounts';
    dispatch(setBankError(errorMessage));
    dispatch(addNotification({
      type: 'error',
      message: errorMessage
    }));
    throw error;
  } finally {
    dispatch(setBankLoading(false));
  }
};

// export const disconnectBank = (connectionId) => async (dispatch) => {
//   try {
//     dispatch(setBankLoading(true));
//     await api.delete(`/api/v1/banks/connections/${connectionId}`);
//     dispatch(removeBankConnection(connectionId));
//     dispatch(addNotification({
//       type: 'success',
//       message: 'Bank disconnected successfully'
//     }));
//   } catch (error) {
//     const errorMessage = error.response?.data?.detail || 'Failed to disconnect bank';
//     dispatch(setBankError(errorMessage));
//     dispatch(addNotification({
//       type: 'error',
//       message: errorMessage
//     }));
//     throw error;
//   } finally {
//     dispatch(setBankLoading(false));
//   }
// };

// Add this new function to your existing bankThunks.js
export const fetchTransactions = (accountId) => async (dispatch) => {
  try {
    dispatch(setBankLoading(true));
    const response = await api.get(`/api/v1/banks/accounts/${accountId}/transactions`);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.detail || 'Failed to fetch transactions';
    dispatch(setBankError(errorMessage));
    dispatch(addNotification({
      type: 'error',
      message: errorMessage
    }));
    throw error;
  } finally {
    dispatch(setBankLoading(false));
  }
};

//
// Add this to your existing thunks
export const disconnectBank = (connectionId) => async (dispatch) => {
  try {
    dispatch(setBankLoading(true));
    await api.delete(`/api/v1/banks/connections/${connectionId}`);
    
    // After successful deletion, fetch updated accounts list
    await dispatch(fetchBankAccounts());
    
    dispatch(addNotification({
      type: 'success',
      message: 'Bank disconnected successfully'
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.detail || 'Failed to disconnect bank';
    dispatch(setBankError(errorMessage));
    dispatch(addNotification({
      type: 'error',
      message: errorMessage
    }));
    throw error;
  } finally {
    dispatch(setBankLoading(false));
  }
};