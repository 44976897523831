import React from 'react';
import { motion } from 'framer-motion';
import { PasswordStrength } from '../../utils/validationRules';

const PasswordStrengthIndicator = ({ strength, className = '' }) => {
  const getColor = () => {
    switch (strength) {
      case PasswordStrength.WEAK:
        return 'bg-red-500';
      case PasswordStrength.MEDIUM:
        return 'bg-yellow-500';
      case PasswordStrength.STRONG:
        return 'bg-green-500';
      case PasswordStrength.VERY_STRONG:
        return 'bg-emerald-500';
      default:
        return 'bg-gray-200';
    }
  };

  const getMessage = () => {
    switch (strength) {
      case PasswordStrength.WEAK:
        return 'Weak password';
      case PasswordStrength.MEDIUM:
        return 'Medium strength';
      case PasswordStrength.STRONG:
        return 'Strong password';
      case PasswordStrength.VERY_STRONG:
        return 'Very strong password';
      default:
        return 'Enter password';
    }
  };

  const strengthScore = {
    [PasswordStrength.WEAK]: 25,
    [PasswordStrength.MEDIUM]: 50,
    [PasswordStrength.STRONG]: 75,
    [PasswordStrength.VERY_STRONG]: 100
  };

  return (
    <div className={`space-y-2 ${className}`}>
      <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
        <motion.div
          className={`h-full ${getColor()}`}
          initial={{ width: 0 }}
          animate={{ width: `${strengthScore[strength] || 0}%` }}
          transition={{ duration: 0.3 }}
        />
      </div>
      <p className={`text-sm ${getColor().replace('bg-', 'text-')}`}>
        {getMessage()}
      </p>
    </div>
  );
};

export default PasswordStrengthIndicator;