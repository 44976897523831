import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: 'light',
  sidebarOpen: false,
  notifications: [],
  modals: {
    settings: false,
    profile: false,
  },
  loading: {
    global: false,
    components: {},
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    setModalState: (state, action) => {
      const { modal, isOpen } = action.payload;
      state.modals[modal] = isOpen;
    },
    setLoading: (state, action) => {
      const { component, isLoading } = action.payload;
      if (component) {
        state.loading.components[component] = isLoading;
      } else {
        state.loading.global = isLoading;
      }
    },
  },
});

export const {
  setTheme,
  toggleSidebar,
  addNotification,
  removeNotification,
  setModalState,
  setLoading,
} = uiSlice.actions;

// Selectors
export const selectTheme = (state) => state.ui.theme;
export const selectSidebarOpen = (state) => state.ui.sidebarOpen;
export const selectNotifications = (state) => state.ui.notifications;
export const selectModalState = (modal) => (state) => state.ui.modals[modal];
export const selectLoading = (component) => (state) =>
  component ? state.ui.loading.components[component] : state.ui.loading.global;

export default uiSlice.reducer;