// src/pages/Dashboard/components/ModelSelector.jsx
const ModelSelector = ({ models, selectedModel, onSelect, currentPlan }) => {
  return (
    <select
      value={selectedModel?.id || ''}
      onChange={(e) => {
        const model = models.find(m => m.id === e.target.value);
        onSelect(model);
      }}
      className="block w-48 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
    >
      {models.map(model => (
        <option 
          key={model.id} 
          value={model.id}
          disabled={!model.isAvailable}
        >
          {model.name} {!model.isAvailable && `(Requires ${model.requiredPlan})`}
        </option>
      ))}
    </select>
  );
};

export default ModelSelector;