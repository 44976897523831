import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  connections: [],
  accounts: [],
  loading: false,
  error: null
};

const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    setBankLoading: (state, action) => {
      state.loading = action.payload;
      if (action.payload) {
        state.error = null;
      }
    },
    setBankError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setBankAccounts: (state, action) => {
      state.accounts = action.payload;
      state.loading = false;
      state.error = null;
    },
    addBankConnection: (state, action) => {
      state.connections.push(action.payload);
    },
    removeBankConnection: (state, action) => {
      state.connections = state.connections.filter(
        conn => conn.id !== action.payload
      );
      state.accounts = state.accounts.filter(
        acc => acc.connection_id !== action.payload
      );
    }
  }
});

export const {
  setBankLoading,
  setBankError,
  setBankAccounts,
  addBankConnection,
  removeBankConnection
} = bankSlice.actions;

export const selectBankAccounts = (state) => state.bank.accounts;
export const selectBankLoading = (state) => state.bank.loading;
export const selectBankError = (state) => state.bank.error;

export default bankSlice.reducer;