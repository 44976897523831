// src/components/Chat/ChatHistory.jsx
import React from 'react';
import { format } from 'date-fns';

const ChatHistory = ({ isOpen, onClose, chats, onSelectChat, isLoading }) => {
  console.log('ChatHistory render:', { isOpen, chatsLength: chats?.length });
  if (!isOpen) return null;

  return (
    <div className="fixed inset-y-0 left-0 w-80 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-30">
      <div className="h-full flex flex-col">
        {/* ... header ... */}
        <div className="flex-1 overflow-y-auto">
          {isLoading ? (
            <div className="text-center py-8 text-gray-500">
              Loading chats...
            </div>
          ) : chats?.length > 0 ? (
            chats.map((chat) => (
              <button
                key={chat.id}
                onClick={() => onSelectChat(chat)}
                className="w-full px-4 py-3 hover:bg-gray-50 flex items-start justify-between text-left border-b border-gray-100"
              >
                <div>
                  <h3 className="text-sm font-medium text-gray-900 truncate max-w-[180px]">
                    {chat.title || 'Untitled Chat'}
                  </h3>
                  <p className="text-xs text-gray-500 mt-1">
                    {format(new Date(chat.created_at), 'MMM d, yyyy h:mm a')}
                  </p>
                  <p className="text-xs text-gray-400 mt-1">
                    {chat.messages?.length || 0} messages
                  </p>
                </div>
                <span className="text-xs text-gray-400 ml-2">
                  {chat.model_provider}
                </span>
              </button>
            ))
          ) : (
            <div className="text-center py-8 text-gray-500">
              No previous chats found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatHistory;