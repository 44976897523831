import React from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../../components/Layouts/MainLayout';
import SEO from '../../components/common/SEO';

const NotFound = () => {
  return (
    <MainLayout>
      <SEO 
        title="Page Not Found - CGPT"
        description="The page you're looking for doesn't exist."
      />
      <div className="flex items-center justify-center min-h-[70vh] px-4">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-blue-600 mb-4">404</h1>
          <h2 className="text-2xl font-semibold mb-4">Page Not Found</h2>
          <p className="text-gray-600 mb-8">
            The page you're looking for doesn't exist or has been moved.
          </p>
          <Link 
            to="/"
            className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Return Home
          </Link>
        </div>
      </div>
    </MainLayout>
  );
};

export default NotFound;