import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import { fetchAnalyticsSummary } from '../../store/thunks/analyticsThunks';

import {
  selectAnalyticsSummary,
  selectAnalyticsLoading,
  selectAnalyticsError,
  selectTimeRange,
  
} from '../../store/slices/analyticsSlice';





const StatsCard = ({ title, value }) => (
  <div className="bg-white overflow-hidden shadow rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900">{value}</dd>
    </div>
  </div>
 );
 
 const Analytics = () => {
  const dispatch = useDispatch();
  const summary = useSelector(selectAnalyticsSummary);
  const loading = useSelector(selectAnalyticsLoading);
  const error = useSelector(selectAnalyticsError);
  const timeRange = useSelector(selectTimeRange);
 
  useEffect(() => {
    dispatch(fetchAnalyticsSummary(timeRange));
  }, [dispatch, timeRange]);
 
  if (loading) return <DashboardLayout><div>Loading...</div></DashboardLayout>;
  if (error) return <DashboardLayout><div>{error}</div></DashboardLayout>;
 
  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold text-gray-900 mb-6">Your Usage Stats</h1>
        
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <StatsCard 
            title="Total Sessions" 
            value={summary.total_sessions || 0} 
          />
          <StatsCard 
            title="Avg Session Time" 
            value={`${Math.round((summary.avg_session_time || 0) / 60)}m`}
          />
          <StatsCard 
            title="Page Views" 
            value={summary.page_views || 0}
          />
        </div>
 
        {summary.features_used?.length > 0 && (
          <div className="mt-8">
            <h2 className="text-lg font-medium mb-4">Recently Used Features</h2>
            <ul className="bg-white shadow rounded-lg divide-y divide-gray-200">
              {summary.features_used.map((feature, idx) => (
                <li key={idx} className="px-6 py-4">
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
 };
 
 export default Analytics;