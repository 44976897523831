// In store/thunks/userThunks.js (create this if it doesn't exist)
import api from '../../services/api';
import { setProfile, setSettings, setLoading, setError } from '../slices/userSlice';

// Profile thunks
export const fetchProfile = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get('/api/v1/users/profile');
    dispatch(setProfile(response.data));
    return response.data;
  } catch (error) {
    dispatch(setError(error.response?.data?.detail || 'Failed to fetch profile'));
    throw error;
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateProfile = (profileData) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.put('/api/v1/users/profile', profileData);
    dispatch(setProfile(response.data));
    return response.data;
  } catch (error) {
    dispatch(setError(error.response?.data?.detail || 'Failed to update profile'));
    throw error;
  } finally {
    dispatch(setLoading(false));
  }
};

// Settings thunks
export const fetchSettings = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get('/api/v1/users/settings');
    dispatch(setSettings(response.data));
    return response.data;
  } catch (error) {
    dispatch(setError(error.response?.data?.detail || 'Failed to fetch settings'));
    throw error;
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateSettings = (settingsData) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.put('/api/v1/users/settings', settingsData);
    dispatch(setSettings(response.data));
    return response.data;
  } catch (error) {
    dispatch(setError(error.response?.data?.detail || 'Failed to update settings'));
    throw error;
  } finally {
    dispatch(setLoading(false));
  }
};