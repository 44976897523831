import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPlan: null,
  status: 'inactive',
  interval: null,
  currentPeriodEnd: null,
  currentPeriodStart: null,
  cancelAtPeriodEnd: false,
  id: null,
  loading: false,
  error: null
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionLoading: (state, action) => {
      
      state.loading = action.payload;
      if (action.payload) {
        state.error = null;
      }
    },
    setSubscriptionError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setSubscriptionData: (state, action) => {
      console.log('Setting subscription data:', action.payload);
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null
      };
    },
    clearSubscription: () => initialState
  }
});

export const {
  setSubscriptionLoading,
  setSubscriptionError,
  setSubscriptionData,
  clearSubscription
} = subscriptionSlice.actions;

export const selectSubscription = (state) => state.subscription;

export default subscriptionSlice.reducer;