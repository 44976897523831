import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';

import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import uiReducer from './slices/uiSlice';
import subscriptionReducer from './slices/subscriptionSlice';
import uiPreferencesReducer from './slices/uiPreferencesSlice';
import analyticsReducer from './slices/analyticsSlice'
import bankReducer from './slices/bankSlice';
import chatReducer from './slices/chatSlice';
import chatHistoryReducer from './slices/chatHistorySlice';



// Added version key to force rehydration
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'user', 'subscription'],
  debug: true
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  ui: uiReducer,
  subscription: subscriptionReducer,
  uiPreferences: uiPreferencesReducer, 
  analytics: analyticsReducer,
  bank: bankReducer , // Add this line
  chat: chatReducer,  // Add this line
  chatHistory: chatHistoryReducer,  // Add this line

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

// Subscribe to store changes to debug state
store.subscribe(() => {
  const state = store.getState();
  console.log('Store updated:', {
    subscriptionState: state.subscription,
    isActive: state.subscription?.status === 'active',
    hasPlan: Boolean(state.subscription?.currentPlan)
  });
});

export const persistor = persistStore(store, null, () => {
  console.log('Rehydration completed, current state:', store.getState());
});

setupListeners(store.dispatch);