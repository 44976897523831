import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle, CheckCircle, Clock, ArrowUpCircle } from 'lucide-react';
import { dismissBanner, selectDismissedBanners } from '../../store/slices/uiPreferencesSlice';
import { XIcon } from 'lucide-react';
import { PLAN_FEATURES } from '../../config/planFeatures';



// const PLAN_FEATURES = {
//   basic: {
//     name: 'Basic',
//     features: ['Dashboard access', 'Basic analytics', 'Email support']
//   },
//   pro: {
//     name: 'Pro',
//     features: ['All Basic features', 'Advanced analytics', 'Priority support', '24/7 phone support']
//   },
//   enterprise: {
//     name: 'Enterprise',
//     features: ['All Pro features', 'Custom integrations', 'Dedicated account manager']
//   }
// };

const SubscriptionStatus = () => {
  const dispatch = useDispatch();
  const dismissedBanners = useSelector(selectDismissedBanners);
  
  const navigate = useNavigate();
  const subscription = useAppSelector(state => state.subscription);

  if (dismissedBanners.includes('subscription-status')) return null;
  console.log('SubscriptionStatus component:', {
    subscription,
    rawState: subscription,
    currentPlan: subscription?.currentPlan,
    status: subscription?.status,
    cancelAtPeriodEnd: subscription?.cancelAtPeriodEnd
  });


  if (!subscription) return null;

  const {
    currentPlan,
    status,
    currentPeriodEnd,
    cancelAtPeriodEnd
  } = subscription;

  const periodEnd = new Date(currentPeriodEnd);
  const now = new Date();
  const daysUntilExpiration = Math.ceil((periodEnd - now) / (1000 * 60 * 60 * 24));
  
  const gracePeriodEnd = new Date(periodEnd);
  gracePeriodEnd.setDate(gracePeriodEnd.getDate() + 7);
  const isInGracePeriod = now > periodEnd && now <= gracePeriodEnd;

  const getStatusDisplay = () => {
    if (isInGracePeriod) {
      return {
        icon: <Clock className="h-5 w-5 text-orange-500" />,
        text: 'Grace Period',
        description: 'Your grace period ends soon. Please renew your subscription to maintain access.',
        className: 'bg-orange-50 border-orange-200'
      };
    }

    if (cancelAtPeriodEnd) {
      return {
        icon: <AlertTriangle className="h-5 w-5 text-yellow-500" />,
        text: 'Canceling Soon',
        description: `Your subscription will end in ${daysUntilExpiration} days.`,
        className: 'bg-yellow-50 border-yellow-200'
      };
    }

    if (status === 'active') {
      return {
        icon: <CheckCircle className="h-5 w-5 text-green-500" />,
        text: 'Active',
        description: `Your ${PLAN_FEATURES[currentPlan]?.name || ''} plan renews in ${daysUntilExpiration} days.`,
        className: 'bg-green-50 border-green-200'
      };
    }

    return {
      icon: <AlertTriangle className="h-5 w-5 text-red-500" />,
      text: 'Inactive',
      description: 'Your subscription is inactive. Resubscribe to regain access.',
      className: 'bg-red-50 border-red-200'
    };
  };

  const statusDisplay = getStatusDisplay();

  return (
    <div className={`relative rounded-lg border p-4 ${statusDisplay.className}`}>
       <button
        onClick={() => dispatch(dismissBanner('subscription-status'))}
        className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
      >
        <XIcon className="h-5 w-5" />
      </button>
      <div className="flex items-start space-x-3">
        <div className="flex-shrink-0">
          {statusDisplay.icon}
        </div>
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900">
              Subscription Status: {statusDisplay.text}
            </p>
            {currentPlan && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                {PLAN_FEATURES[currentPlan]?.name || currentPlan}
              </span>
            )}
          </div>
          <p className="mt-1 text-sm text-gray-500">
            {statusDisplay.description}
          </p>

          {/* Current Plan Features */}
          {currentPlan && PLAN_FEATURES[currentPlan] && (
            <div className="mt-3">
              <h4 className="text-sm font-medium text-gray-900">Your Current Features:</h4>
              <ul className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2">
                {PLAN_FEATURES[currentPlan].features.featureList.map((feature, index) => (
                  <li key={index} className="flex items-center text-sm text-gray-500">
                    <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Action Buttons */}
          <div className="mt-4 flex space-x-3">
            {(isInGracePeriod || status !== 'active') && (
              <button
                onClick={() => navigate('/subscribe')}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Renew Subscription
              </button>
            )}
            {status === 'active' && !cancelAtPeriodEnd && (
              <button
                onClick={() => navigate('/subscription')}
                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Manage Subscription
              </button>
            )}
            {cancelAtPeriodEnd && (
              <button
                onClick={() => navigate('/subscription')}
                className="inline-flex items-center px-3 py-2 border border-blue-300 text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ArrowUpCircle className="h-4 w-4 mr-1" />
                Reactivate
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionStatus;