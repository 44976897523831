import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBillingHistory } from '../../store/thunks/subscriptionThunks';
import { DownloadIcon, ChevronDownIcon } from 'lucide-react';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import api from '../../services/api';



const BillingHistory = () => {
  const dispatch = useDispatch();
  const [billingHistory, setBillingHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);

  // In BillingHistory.js, modify the useEffect:
  useEffect(() => {
    const fetchBillingHistory = async () => {
      try {
        setIsLoading(true);
        const response = await dispatch(getBillingHistory());
        setBillingHistory(response); // Remove .unwrap()
      } catch (err) {
        setError(err.message || 'Failed to fetch billing history');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingHistory();
  }, [dispatch]);

  // Update the downloadInvoice function
  const downloadInvoice = async (invoiceId) => {
    try {
      const loadingToast = toast.loading('Downloading invoice...');
      const response = await api.get(`/api/v1/subscription/invoice/${invoiceId}`);
      toast.dismiss(loadingToast);
      window.open(response.data.url, '_blank');
      toast.success('Invoice downloaded successfully');
    } catch (error) {
      toast.error(error.response?.data?.detail || 'Failed to download invoice');
    }
  };

  if (isLoading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
        {[1, 2, 3].map((i) => (
          <div key={i} className="h-16 bg-gray-100 rounded mb-2"></div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error loading billing history</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Billing History
        </h3>
        
        <div className="mt-4">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Description
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Amount
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    {/* // Find this part in your BillingHistory.js */}
                    <tbody className="bg-white divide-y divide-gray-200">
                      {billingHistory.map((item) => (
                        <React.Fragment key={item.id}>
                          <tr className="hover:bg-gray-50 cursor-pointer" onClick={() => setExpandedRow(expandedRow === item.id ? null : item.id)}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {format(new Date(item.date), 'MMM d, yyyy')}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.description}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              ${item.amount.toFixed(2)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                item.status === 'paid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                              }`}>
                                {item.status}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {/* Change this button section */}
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  downloadInvoice(item.id);  // Changed from item.invoice_id to item.id
                                }}
                                className="text-blue-600 hover:text-blue-900"
                              >
                                <DownloadIcon className="h-5 w-5" />
                              </button>
                            </td>
                          </tr>
                          {expandedRow === item.id && (
                            <tr>
                              <td colSpan="5" className="px-6 py-4 bg-gray-50">
                                <div className="text-sm text-gray-900">
                                  <h4 className="font-medium mb-2">Transaction Details</h4>
                                  <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                                    <div>
                                      {/* Change this section */}
                                      <dt className="text-gray-500">Invoice Number</dt>
                                      <dd className="mt-1">{item.invoice_number}</dd>  {/* Changed from item.invoice_id */}
                                    </div>
                                    <div>
                                      <dt className="text-gray-500">Payment Method</dt>
                                      <dd className="mt-1">•••• {item.last4}</dd>
                                    </div>
                                    {item.proration && (
                                      <div className="col-span-2">
                                        <dt className="text-gray-500">Proration Details</dt>
                                        <dd className="mt-1">{item.proration_details}</dd>
                                      </div>
                                    )}
                                  </dl>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingHistory;