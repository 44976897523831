import React from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSubscription } from '../../store/slices/subscriptionSlice';
// import { Button } from '@/components/ui/';
// import { Button } from "@/components/ui/button"
import { ArrowUpIcon, LockIcon } from 'lucide-react';

const FEATURE_REQUIREMENTS = {
  '/analytics': {
    minimumPlan: 'pro',
    features: [
      'Advanced analytics dashboard',
      'Custom report generation',
      'Data export capabilities',
      'Historical data access'
    ]
  },
  '/admin': {
    minimumPlan: 'enterprise',
    features: [
      'Team management',
      'Role-based access control',
      'Audit logging',
      'Priority support'
    ]
  }
  // Add more feature requirements as needed
};

const UpgradePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const subscription = useSelector(selectSubscription);
  
  const fromPath = searchParams.get('from') || '';
  const featureRequirements = FEATURE_REQUIREMENTS[fromPath] || {
    minimumPlan: 'pro',
    features: ['Additional features']
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto text-center">
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex justify-center">
              <div className="rounded-full bg-yellow-100 p-3">
                <LockIcon className="h-6 w-6 text-yellow-600" />
              </div>
            </div>
            
            <h3 className="mt-4 text-lg font-medium text-gray-900">
              Upgrade Required
            </h3>
            
            <p className="mt-2 text-sm text-gray-500">
              This feature requires the {featureRequirements.minimumPlan} plan or higher.
              You're currently on the {subscription?.currentPlan || 'no'} plan.
            </p>

            <div className="mt-4">
              <h4 className="text-sm font-medium text-gray-900">
                What you'll get with the upgrade:
              </h4>
              <ul className="mt-2 list-disc pl-5 text-sm text-gray-500 text-left">
                {featureRequirements.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>

            <div className="mt-6 space-y-4">
              <button
                onClick={() => navigate('/subscribe')}
                className="w-full flex items-center justify-center"
              >
                <ArrowUpIcon className="h-5 w-5 mr-2" />
                Upgrade Now
              </button>
              
              <button
                variant="outline"
                onClick={() => navigate(-1)}
                className="w-full"
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePage;