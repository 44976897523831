import api from '../../services/api';
import {
  setSubscriptionLoading,
  setSubscriptionError,
  setSubscriptionData
} from '../slices/subscriptionSlice';

// in store/thunks/subscriptionThunks.js
export const createSubscription = (planId, paymentMethodId, interval) => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    const response = await api.post('/api/v1/subscription/create', {
      plan_id: planId,
      payment_method_id: paymentMethodId,
      interval
    });
    
    // Immediately fetch updated subscription status after creation
    const subscriptionStatus = await dispatch(fetchSubscriptionStatus());
    return {
      ...response.data,
      subscriptionStatus
    };
  } catch (error) {
    dispatch(setSubscriptionError(error.response?.data?.detail || 'Failed to create subscription'));
    throw error;
  } finally {
    dispatch(setSubscriptionLoading(false));
  }
};

// in store/thunks/subscriptionThunks.js
export const fetchSubscriptionStatus = () => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    const response = await api.get('/api/v1/subscription/status');

    const subscriptionData = {
      currentPlan: response.data.plan,
      status: response.data.status,
      interval: response.data.interval,
      currentPeriodEnd: new Date(response.data.current_period_end).toISOString(),
      currentPeriodStart: new Date(response.data.current_period_start).toISOString(),
      cancelAtPeriodEnd: response.data.cancel_at_period_end,
      id: response.data.id,
      userId: response.data.user_id  // Add this
    };

    dispatch(setSubscriptionData(subscriptionData));
    return subscriptionData;
  } catch (error) {
    console.error('Error fetching subscription:', error);
    let errorMessage = 'Failed to fetch subscription';
    
    if (error.response?.status === 404) {
      errorMessage = 'No subscription found';
    } else if (!error.response) {
      errorMessage = 'Network error. Please check your connection.';
    }
    
    dispatch(setSubscriptionError(errorMessage));
    // Don't throw the error, return null instead
    return null;
  } finally {
    dispatch(setSubscriptionLoading(false));
  }
};

// Cancel subscription
export const cancelSubscription = () => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    await api.post('/api/v1/subscription/cancel');
    await dispatch(fetchSubscriptionStatus());
  } catch (error) {
    dispatch(setSubscriptionError(error.response?.data?.detail || 'Failed to cancel subscription'));
    throw error;
  }
};

// Change subscription plan
export const changePlan = (planId) => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    await api.post(`/api/v1/subscription/change-plan/${planId}`);
    await dispatch(fetchSubscriptionStatus());
  } catch (error) {
    dispatch(setSubscriptionError(error.response?.data?.detail || 'Failed to change subscription plan'));
    throw error;
  }
};

// Update payment method
export const updatePaymentMethod = (paymentMethodId) => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    await api.post('/api/v1/subscription/update-payment-method', {
      payment_method_id: paymentMethodId
    });
    await dispatch(fetchSubscriptionStatus());
  } catch (error) {
    dispatch(setSubscriptionError(error.response?.data?.detail || 'Failed to update payment method'));
    throw error;
  }
};

// Add to your existing thunks
// Reactivate subscription
export const reactivateSubscription = () => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    await api.post('/api/v1/subscription/reactivate');
    
    // Fetch updated subscription status after reactivation
    const updatedSubscription = await dispatch(fetchSubscriptionStatus());
    return updatedSubscription;
  } catch (error) {
    dispatch(setSubscriptionError(error.response?.data?.detail || 'Failed to reactivate subscription'));
    throw error;
  }
};

// In store/thunks/subscriptionThunks.js
export const getBillingHistory = () => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    const response = await api.get('/api/v1/subscription/billing-history');
    return response.data;  // Return the data directly
  } catch (error) {
    dispatch(setSubscriptionError('Failed to fetch billing history'));
    throw error;
  } finally {
    dispatch(setSubscriptionLoading(false));
  }
};