// src/store/slices/chatHistorySlice.js
import { createSlice } from '@reduxjs/toolkit';

const chatHistorySlice = createSlice({
  name: 'chatHistory',
  initialState: {
    chats: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchChatsStart: (state) => {
      state.loading = true;
    },
    fetchChatsSuccess: (state, action) => {
      state.chats = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchChatsError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { fetchChatsStart, fetchChatsSuccess, fetchChatsError } = chatHistorySlice.actions;
export default chatHistorySlice.reducer;
export const selectChats = (state) => state.chatHistory.chats;
export const selectChatsLoading = (state) => state.chatHistory.loading;