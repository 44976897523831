import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'; // Add useLocation
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { createSubscription, fetchSubscriptionStatus } from '../../store/thunks/subscriptionThunks';

const CheckoutForm = ({ planId, interval, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Add this hook

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Early return if Stripe isn't loaded yet
    if (!stripe || !elements) {
      setError('Payment system not initialized. Please try again.');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage('Processing your subscription...');

    try {
      // Get payment method
      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (stripeError) {
        setError(stripeError.message);
        return;
      }

      if (!paymentMethod) {
        setError('Failed to process payment method. Please try again.');
        return;
      }

      // Create subscription
      const result = await dispatch(createSubscription(planId, paymentMethod.id, interval));

      // Handle client secret if payment needs confirmation
      if (result?.client_secret) {
        const { error: confirmError } = await stripe.confirmCardPayment(result.client_secret);
        if (confirmError) {
          throw new Error(confirmError.message);
        }
      }

      // Fetch fresh subscription status after creation
      await dispatch(fetchSubscriptionStatus());

      setMessage('Subscription created successfully! Redirecting...');
      
      // Get the intended destination or default to dashboard
      const destination = location.state?.from?.pathname || '/dashboard';
      
      // Delay redirect to show success message
      setTimeout(() => {
        navigate(destination);
      }, 2000);

    } catch (err) {
      setError(
        err.response?.data?.detail || 
        err.message || 
        'An error occurred while processing your subscription'
      );
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <CardElement
            className="p-3 border rounded-md"
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
              hidePostalCode: true, // Optional: hide postal code field
            }}
          />
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md text-red-600 text-sm">
            {error}
          </div>
        )}

        {/* Success/Processing Message */}
        {message && (
          <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-md text-green-600 text-sm">
            {message}
          </div>
        )}

        <div className="mt-4 space-x-3">
          <button
            type="submit"
            disabled={!stripe || loading}
            className={`
              inline-flex justify-center px-4 py-2 text-sm font-medium text-white 
              bg-blue-600 border border-transparent rounded-md 
              hover:bg-blue-700 focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-blue-500
              ${loading ? 'opacity-50 cursor-not-allowed' : ''}
            `}
          >
            {loading ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
              </span>
            ) : (
              'Subscribe'
            )}
          </button>

          <button
            type="button"
            onClick={onCancel}
            disabled={loading}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;