// Create a new file: src/config/planFeatures.js
export const PLAN_FEATURES = {
  basic: {
    id: 'basic',
    name: 'Basic',
    description: 'Perfect for individual users',
    monthly: 9.99,
    yearly: 99.99,
    features: {
      projects: 10,
      bank_connections: 1,
      chat_history_days: 7,
      storage: '5GB',
      analytics: 'basic',
      support: 'email',
      allowed_models: ['gpt-3.5-turbo'],
      routes: ['/dashboard', '/settings'],
      featureList: [
        'Up to 10 projects',
        'Basic analytics',
        'Email support',
        '5GB storage',
        'Connect 1 bank account',
        'Access to GPT-3.5'
      ]
    }
  },
  pro: {
    id: 'pro',
    name: 'Pro',
    description: 'Ideal for professionals',
    monthly: 19.99,
    yearly: 199.99,
    features: {
      projects: -1, // unlimited
      bank_connections: 3,
      chat_history_days: 30,
      storage: '50GB',
      analytics: 'advanced',
      support: 'priority',
      allowed_models: ['gpt-3.5-turbo', 'grok-beta'],
      routes: ['/dashboard', '/settings', '/analytics'],
      featureList: [
        'Unlimited projects',
        'Advanced analytics',
        'Priority support',
        '50GB storage',
        'Connect up to 3 bank accounts',
        'Access to GPT-3.5 and Grok',
        'Team collaboration',
        'Custom integrations'
      ]
    }
  },
  enterprise: {
    id: 'enterprise',
    name: 'Enterprise',
    description: 'For large organizations',
    monthly: 49.99,
    yearly: 499.99,
    features: {
      projects: -1,
      bank_connections: -1,
      chat_history_days: -1,
      storage: 'unlimited',
      analytics: 'enterprise',
      support: 'dedicated',
      allowed_models: ['gpt-3.5-turbo', 'grok-beta', 'claude-3-sonnet-20240229'],
      routes: ['/dashboard', '/settings', '/analytics', '/admin'],
      featureList: [
        'Everything in Pro',
        'Dedicated support',
        'Unlimited storage',
        'Connect unlimited bank accounts',
        'Access to all AI models',
        'Custom features',
        'SLA guarantee',
        'Advanced security'
      ]
    }
  }
};

export const MODEL_REQUIREMENTS = {
  'gpt-3.5-turbo': ['basic', 'pro', 'enterprise'],
  'grok-beta': ['pro', 'enterprise'],
  'claude-3-sonnet-20240229': ['enterprise']
};

export const getRequiredPlanForModel = (modelId) => {
  return MODEL_REQUIREMENTS[modelId]?.[0] || 'enterprise';
};