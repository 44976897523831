import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';

const ReactivationBanner = ({ subscription, onReactivate }) => {
  if (!subscription.cancelAtPeriodEnd) return null;

  const endDate = new Date(subscription.currentPeriodEnd).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Your subscription will end on {endDate}. You can reactivate your subscription to keep your current benefits.
          </p>
          <div className="mt-4">
            <div className="flex space-x-3">
              <button
                type="button"
                onClick={onReactivate}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >
                Reactivate Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactivationBanner;