// src/store/thunks/chatThunks.js  <-- NEW FILE
import api from '../../services/api';
import { setChatLoading, setChatError, addMessage } from '../slices/chatSlice';
import { addNotification } from '../slices/uiSlice';
import { fetchChatsStart, fetchChatsSuccess, fetchChatsError } from '../slices/chatHistorySlice';
import { toast } from 'react-hot-toast';



// export const sendChatMessage = ({ message, accountId, modelId, provider }) => async (dispatch) => {
//   try {
//     dispatch(setChatLoading(true));
//     const response = await api.post('/api/v1/chat/message', {
//       message,
//       account_id: accountId,
//       model_id: modelId,
//       model_provider: provider
//     });
    
//     dispatch(addMessage({
//       role: 'assistant',
//       content: response.data.message,
//       metadata: response.data.metadata
//     }));
    
//     return response.data;
//   } catch (error) {
//     const errorMessage = error.response?.data?.detail || 'Failed to send message';
//     dispatch(setChatError(errorMessage));
//     dispatch(addNotification({
//       type: 'error',
//       message: errorMessage
//     }));
//     throw error;
//   } finally {
//     dispatch(setChatLoading(false));
//   }
// };
// src/store/thunks/chatThunks.js
export const sendChatMessage = ({ message, accountId, modelId, provider, chatId }) => async (dispatch) => {
  try {
    dispatch(setChatLoading(true));
    dispatch(addMessage({ role: 'user', content: message }));

    const response = await api.post('/api/v1/chat/message', {
      message,
      account_id: accountId,
      model_provider: provider,
      model_id: modelId,
      chat_id: chatId
    });
    
    dispatch(addMessage({
      role: 'assistant',
      content: response.data.message,
      metadata: response.data.metadata
    }));
    
    return response.data;
  } catch (error) {
    // Handle the 403 error specifically
    if (error.response?.status === 403) {
      toast.error(error.response.data.detail || 'You do not have access to this model. Please upgrade your plan.');
      // Remove the failed message from the chat
      // Optional: dispatch an action to remove the last message
    } else {
      toast.error('Failed to send message. Please try again.');
    }
    throw error;
  } finally {
    dispatch(setChatLoading(false));
  }
};

// In chatThunks.js
export const fetchChatHistory = () => async (dispatch) => {
  console.log('Starting fetchChatHistory');
  dispatch(fetchChatsStart());
  try {
    const response = await api.get('/api/v1/chat/chats');
    console.log('Chat history API response:', response.data);
    dispatch(fetchChatsSuccess(response.data));
    console.log('Dispatched fetchChatsSuccess');
    return response.data;
  } catch (error) {
    console.error('Error in fetchChatHistory:', error);
    dispatch(fetchChatsError(error.message));
    throw error;
  }
};