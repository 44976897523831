import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dismissedBanners: [],
  lastDismissed: {} // Timestamps for when banners were last dismissed
};

const uiPreferencesSlice = createSlice({
  name: 'uiPreferences',
  initialState,
  reducers: {
    dismissBanner: (state, action) => {
      const bannerId = action.payload;
      if (!state.dismissedBanners.includes(bannerId)) {
        state.dismissedBanners.push(bannerId);
        state.lastDismissed[bannerId] = new Date().toISOString();
      }
    },
    resetBannerDismissal: (state, action) => {
      const bannerId = action.payload;
      state.dismissedBanners = state.dismissedBanners.filter(id => id !== bannerId);
      delete state.lastDismissed[bannerId];
    }
  }
});

export const { dismissBanner, resetBannerDismissal } = uiPreferencesSlice.actions;
export const selectDismissedBanners = state => state.uiPreferences.dismissedBanners;
export default uiPreferencesSlice.reducer;