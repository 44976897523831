import { toast } from 'react-hot-toast';

export const useSubscriptionToasts = () => {
  const notifications = {
    success: {
      planChanged: (planName) => toast.success(
        `Successfully changed to ${planName} plan`,
        { duration: 4000 }
      ),
      
      paymentUpdated: () => toast.success(
        'Payment method successfully updated',
        { duration: 4000 }
      ),
      
      subscriptionCanceled: (endDate) => toast.success(
        `Subscription canceled. Service continues until ${endDate}`,
        { duration: 5000 }
      ),
      
      subscriptionReactivated: () => toast.success(
        'Subscription successfully reactivated',
        { duration: 4000 }
      ),
      invoiceDownloaded: () => toast.success(
        'Invoice downloaded successfully',
        { duration: 4000 }
      ),

    },
    
    error: {
      planChange: (error) => toast.error(
        error?.message || 'Failed to change plan. Please try again.',
        { duration: 5000 }
      ),
      
      paymentUpdate: (error) => toast.error(
        error?.message || 'Failed to update payment method. Please try again.',
        { duration: 5000 }
      ),
      
      subscriptionCancel: (error) => toast.error(
        error?.message || 'Failed to cancel subscription. Please try again.',
        { duration: 5000 }
      ),
      
      subscriptionReactivate: (error) => toast.error(
        error?.message || 'Failed to reactivate subscription. Please try again.',
        { duration: 5000 }
      ),
      
      general: (error) => toast.error(
        error?.message || 'An unexpected error occurred. Please try again.',
        { duration: 5000 }
      ),

      downloadInvoice: (error) => toast.error(
        error?.message || 'Failed to download invoice',
        { duration: 5000 }
      ),
      loadBillingHistory: (error) => toast.error(
        error?.message || 'Failed to load billing history',
        { duration: 5000 }
      ),
    },
    
    loading: {
      start: (message) => toast.loading(message, { duration: 0 }),
      dismiss: (toastId) => toast.dismiss(toastId)
    }
  };

  return notifications;
};