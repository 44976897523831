import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AuthLayout from './components/AuthLayout';
import LoadingButton from '../../components/common/LoadingButton';

const SignIn = () => {
  const { loginWithRedirect, isLoading } = useAuth0();
  const navigate = useNavigate();

  const handleSignIn = async () => {
    await loginWithRedirect({
      appState: { returnTo: '/dashboard' }
    });
  };

  return (
    <AuthLayout 
      title="Welcome back"
      subtitle={
        <span>
          New to CGPT?{' '}
          <Link to="/signup" className="text-blue-600 hover:text-blue-500">
            Create an account
          </Link>
        </span>
      }
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="space-y-6"
      >
        <LoadingButton
          onClick={handleSignIn}
          isLoading={isLoading}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Sign in with Auth0
        </LoadingButton>

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">
                Or continue with
              </span>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-3">
            <button
              onClick={() => loginWithRedirect({
                connection: 'google-oauth2'
              })}
              className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Google
            </button>
            <button
              onClick={() => loginWithRedirect({
                connection: 'github'
              })}
              className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              GitHub
            </button>
          </div>
        </div>
      </motion.div>
    </AuthLayout>
  );
};

export default SignIn;