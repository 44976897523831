import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import planFeatures from './planFeatures';

export class PermissionsManager {
  constructor(subscriptionPlan) {
    this.currentPlan = subscriptionPlan;
    this.features = planFeatures[subscriptionPlan] || planFeatures.basic;
  }

  canCreateProject(currentProjectCount) {
    if (this.features.maxProjects === -1) return true;
    return currentProjectCount < this.features.maxProjects;
  }

  canUploadFile(fileSize, currentStorageUsed) {
    if (this.features.maxStorage === -1) return true;
    return (currentStorageUsed + fileSize) <= this.features.maxStorage;
  }

  canAccessFeature(featureName) {
    return Boolean(this.features.features[featureName]);
  }

  getFeatureLevel(featureName) {
    return this.features.features[featureName];
  }

  getLimits() {
    return this.features.limits;
  }

  hasReachedRequestLimit(currentRequests) {
    if (this.features.limits.requestsPerDay === -1) return false;
    return currentRequests >= this.features.limits.requestsPerDay;
  }

  canAddCollaborators(currentCollaborators) {
    if (this.features.features.collaborators === -1) return true;
    return currentCollaborators < this.features.features.collaborators;
  }

  getAvailableModels() {
    return this.features.limits.modelOptions;
  }
}

const UpgradePrompt = ({ feature, requiredLevel, currentPlan, message }) => {
  const navigate = useNavigate();
  
  return (
    <div className="p-4 bg-blue-50 border border-blue-200 rounded-md">
      <h3 className="text-lg font-medium text-blue-900">
        Upgrade Required
      </h3>
      <p className="mt-2 text-sm text-blue-700">
        {message || (requiredLevel 
          ? `This feature requires ${requiredLevel} access level. Please upgrade your plan.`
          : `This feature is not available in your current ${currentPlan} plan.`
        )}
      </p>
      <button 
        onClick={() => navigate('/subscribe')}
        className="mt-3 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
      >
        View Plans
      </button>
    </div>
  );
};

export const useSubscriptionPermissions = () => {
  const subscription = useSelector(state => state.subscription);
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    if (subscription?.currentPlan && subscription?.status === 'active') {
      setPermissions(new PermissionsManager(subscription.currentPlan));
    }
  }, [subscription?.currentPlan, subscription?.status]);

  return permissions;
};

export const withSubscriptionGuard = (WrappedComponent, requiredFeature, requiredLevel = null) => {
  return function SubscriptionGuardComponent(props) {
    const subscription = useSelector(state => state.subscription);
    const permissions = useSubscriptionPermissions();
    
    if (!subscription || subscription.status !== 'active') {
      return <UpgradePrompt message="Please subscribe to access this feature" />;
    }

    if (!permissions) {
      return <div>Loading permissions...</div>;
    }

    if (requiredLevel) {
      const featureLevel = permissions.getFeatureLevel(requiredFeature);
      if (!featureLevel || featureLevel !== requiredLevel) {
        return <UpgradePrompt 
          feature={requiredFeature} 
          requiredLevel={requiredLevel}
          currentPlan={subscription.currentPlan}
        />;
      }
    } else {
      if (!permissions.canAccessFeature(requiredFeature)) {
        return <UpgradePrompt 
          feature={requiredFeature}
          currentPlan={subscription.currentPlan}
        />;
      }
    }

    return <WrappedComponent {...props} permissions={permissions} />;
  };
};

export const FeatureWrapper = ({ children, feature, level = null }) => {
  const subscription = useSelector(state => state.subscription);
  const permissions = useSubscriptionPermissions();

  if (!subscription || subscription.status !== 'active') {
    return <UpgradePrompt message="Please subscribe to access this feature" />;
  }

  if (!permissions) {
    return null;
  }

  if (level) {
    const featureLevel = permissions.getFeatureLevel(feature);
    if (!featureLevel || featureLevel !== level) {
      return <UpgradePrompt 
        feature={feature} 
        requiredLevel={level}
        currentPlan={subscription.currentPlan}
      />;
    }
  } else {
    if (!permissions.canAccessFeature(feature)) {
      return <UpgradePrompt 
        feature={feature}
        currentPlan={subscription.currentPlan}
      />;
    }
  }

  return children;
};