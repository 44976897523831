import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';


const LandingHero = () => {
  return (
    <div className="pt-24 pb-12 px-4">
      <div className="grid md:grid-cols-2 gap-12 items-center max-w-6xl mx-auto">
        {/* Left Column - Animated Content */}
        <motion.div 
          className="space-y-6"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.p 
            className="text-sm font-semibold text-blue-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Welcome to CGPT
          </motion.p>

          <motion.h1 
            className="text-4xl md:text-5xl font-bold text-gray-900"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            Your Personal AI Financial Assistant
          </motion.h1>

          <motion.h2 
            className="text-xl text-gray-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            Connect your bank accounts and get instant insights, analysis, and answers about your finances powered by multiple AI models
          </motion.h2>

          <div className="flex gap-4">
            <Link to="/signup">
              <motion.button 
                className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started Now
              </motion.button>
            </Link>
            <motion.button 
              className="border border-blue-600 text-blue-600 px-8 py-3 rounded-lg hover:bg-blue-50 transition-colors"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Watch Demo
            </motion.button>
          </div>
        </motion.div>

        {/* Right Column - Video Demo */}
        <motion.div 
          className="rounded-lg overflow-hidden shadow-xl bg-white aspect-video"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Replace with your actual video component */}
          <div className="w-full h-full bg-gradient-to-br from-blue-50 to-blue-100 flex items-center justify-center">
            <div className="text-blue-600 text-xl">Demo Video Coming Soon</div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LandingHero;