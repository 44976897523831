// components/AuthStateManager.js
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../store/slices/authSlice';

function AuthStateManager() {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently();
          dispatch(setCredentials({ token, user }));
        } catch (error) {
          console.error('Error getting token:', error);
        }
      }
    };

    getToken();
  }, [getAccessTokenSilently, isAuthenticated, dispatch, user]);

  return null;
}

export default AuthStateManager;