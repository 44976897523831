import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSubscriptionPermissions } from '../utils/permissions/PermissionsManager';
import { selectSubscription } from '../store/slices/subscriptionSlice';
import { fetchSubscriptionStatus } from '../store/thunks/subscriptionThunks'; // Fixed import path

const TestPermissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscription = useSelector(selectSubscription);
  const permissions = useSubscriptionPermissions();

  useEffect(() => {
    dispatch(fetchSubscriptionStatus());
  }, [dispatch]);

  if (subscription.loading) {
    return (
      <div className="p-6 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (subscription.error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 rounded p-4">
          <h3 className="text-red-800 font-medium">Error</h3>
          <p className="text-red-700 mt-1">{subscription.error}</p>
        </div>
      </div>
    );
  }

  if (!permissions || !subscription.currentPlan) {
    return (
      <div className="p-6">
        <div className="bg-yellow-50 border border-yellow-200 rounded p-4">
          <h3 className="text-yellow-800 font-medium">No Active Subscription</h3>
          <p className="text-yellow-700 mt-1">
            You don't have an active subscription. Please subscribe to access features.
          </p>
          <button
            onClick={() => navigate('/subscribe')}
            className="mt-3 px-4 py-2 bg-yellow-100 text-yellow-800 rounded-md hover:bg-yellow-200"
          >
            View Plans
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <h2 className="text-2xl font-bold">Permissions Test Dashboard</h2>
      
      {/* Current Subscription */}
      <div className="bg-white shadow rounded-lg p-4">
        <h3 className="text-lg font-medium">Current Subscription</h3>
        <div className="mt-2">
          <p>Plan: {subscription.currentPlan}</p>
          <p>Status: {subscription.status}</p>
          <p>Period End: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}</p>
        </div>
      </div>

      {/* Feature Access Tests */}
      <div className="space-y-4">
        <h3 className="text-xl font-semibold">Feature Access Tests</h3>
        
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4 bg-gray-100 rounded">
            <h4 className="font-medium">Project Creation</h4>
            <p>Can Create Project (5 existing): {permissions.canCreateProject(5) ? '✅' : '❌'}</p>
            <p>Can Create Project (10 existing): {permissions.canCreateProject(10) ? '✅' : '❌'}</p>
          </div>

          <div className="p-4 bg-gray-100 rounded">
            <h4 className="font-medium">Storage Usage</h4>
            <p>Can Upload 1GB: {permissions.canUploadFile(1024 * 1024 * 1024, 0) ? '✅' : '❌'}</p>
            <p>Can Upload 10GB: {permissions.canUploadFile(10 * 1024 * 1024 * 1024, 0) ? '✅' : '❌'}</p>
          </div>
        </div>
      </div>

      {/* Plan Features */}
      <div className="bg-blue-50 rounded-lg p-4">
        <h3 className="text-lg font-medium">Plan Features</h3>
        <pre className="mt-2 p-4 bg-white rounded overflow-auto">
          {JSON.stringify(permissions.features, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default TestPermissions;