import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AuthLayout from './components/AuthLayout';
import LoadingButton from '../../components/common/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { setLoading, setError } from '../../store/slices/authSlice';
import { addNotification } from '../../store/slices/uiSlice';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  
  // Get state from Redux
  const isLoading = useAppSelector(state => state.auth.loading);
  const authError = useAppSelector(state => state.auth.error);

  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email.trim()) return;

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      // Simulate API call - replace with actual password reset logic later
      await new Promise(resolve => setTimeout(resolve, 1000));

      setIsSubmitted(true);
      dispatch(addNotification({
        id: Date.now(),
        type: 'success',
        message: 'Password reset link has been sent to your email'
      }));
    } catch (error) {
      dispatch(setError('Failed to send reset link'));
      dispatch(addNotification({
        id: Date.now(),
        type: 'error',
        message: 'Failed to send reset link. Please try again.'
      }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  if (isSubmitted) {
    return (
      <AuthLayout title="Check your email">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
            <svg
              className="h-6 w-6 text-green-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          </div>
          <p className="text-sm text-gray-600 mb-4">
            If an account exists with {email}, you will receive a password reset link shortly.
          </p>
          <Link
            to="/signin"
            className="text-sm font-medium text-blue-600 hover:text-blue-500"
          >
            Return to sign in
          </Link>
        </motion.div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout 
      title="Reset your password"
      subtitle="Enter your email address and we'll send you a link to reset your password."
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        {authError && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            className="mb-4 p-3 rounded-md bg-red-50 border border-red-200"
          >
            <p className="text-sm text-red-600">{authError}</p>
          </motion.div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label 
              htmlFor="email" 
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your email"
              />
            </div>
          </div>

          <LoadingButton
            type="submit"
            isLoading={isLoading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Send reset link
          </LoadingButton>

          <div className="text-center">
            <Link
              to="/signin"
              className="text-sm font-medium text-blue-600 hover:text-blue-500"
            >
              Back to sign in
            </Link>
          </div>
        </form>
      </motion.div>
    </AuthLayout>
  );
};

export default ForgotPassword;